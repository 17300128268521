<template>
  <div class="flex-1 flex flex-col">
    <safe-html
      as="p"
      class="mt-12 text-4xl leading-3xl font-bold text-on-surface-elevation-1 shrink-0 max-w-[36.2rem] group-hover:text-brand-primary"
      :html="title"
    />
    <div class="mt-20 flex flex-col gap-4 max-w-320">
      <safe-html
        v-if="subTitle"
        as="p"
        class="text-md leading-lg font-regular text-on-surface-elevation-3"
        :html="subTitle"
      />
      <slot></slot>
    </div>
    <div class="absolute bottom-[1.6rem] right-24">
      <img :src="!isBank ? ImageAssets.BusinessBankImage : ImageAssets.BusinessBankImage2" alt="" />
    </div>
  </div>
  <div v-if="isRejectionOnly" class="mt-auto flex gap-12 shrink-0 relative z-[2] max-w-230">
    <s-button
      variant="tertiary"
      size="md"
      class="min-w-[11.2rem] hover-interaction-overlay"
      @click="emit('onViewHistory')"
    >
      {{ $t('studio.business_bank_setting.btn_details') }}
    </s-button>
  </div>
</template>
<script lang="ts" setup>
import * as ImageAssets from '@/assets/images/business-bank';
import safeHtml from '@/components/common/safe-html.vue';

defineProps<{
  title: string;
  subTitle?: string;
  isBank?: boolean;
  isRejectionOnly?: boolean;
}>();

const emit = defineEmits<{
  onViewHistory: [];
}>();
</script>
