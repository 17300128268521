<template>
  <!-- 사업자• 은행 정보 설정 -->
  <st-title :title="$t('studio.business_bank_setting.title')">
    <s-menu-popup
      v-if="isOwner"
      :distance="4"
      :offset="[0, 0]"
      placement="bottom-end"
      trigger="click"
      class="s-menu-popup"
    >
      <template #target>
        <button
          type="button"
          class="flex gap-8 items-center text-on-surface-elevation-2 text-md leading-sm font-medium h-24"
        >
          {{ $t('studio.business_bank_setting.group_settings') }}
          <s-icon icon="ic-v2-control-arrow-down-line" size="3xl" />
        </button>
      </template>
      <template #menuItems>
        <s-menu-popup-item
          icon="ic-v2-navigation-my-activity-line"
          value="business"
          class="min-w-160"
          @click="onClickGroupHistoryMenu"
        >
          {{ $t('studio.business_bank_setting.group_history') }}
        </s-menu-popup-item>
        <s-menu-popup-item
          icon="ic-v2-stove-symbol-line"
          value="bank"
          class="min-w-160"
          @click="onClickDiscontinueMenu"
        >
          {{ $t('studio.business_bank_setting.group_discontinue') }}
        </s-menu-popup-item>
      </template>
    </s-menu-popup>
  </st-title>
  <div class="mt-40 grid grid-cols-2 gap-24">
    <business-info />
    <bank-info />
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

import { deleteGroupApi } from '@/apis/group.api';
import BankInfo from '@/components/business-bank-info/bank-info/index.vue';
import BusinessInfo from '@/components/business-bank-info/business-info/index.vue';
import StTitle from '@/components/common/st-title.vue';
import { showAlert, showConfirm } from '@/composables/useDialog';
import { COMMON_ERROR_MESSAGE_KEY, STATUS_CODE } from '@/constants/error.const';
import { GROUP_REGISTER_PAGE_URL, HOME_PAGE_URL } from '@/constants/url.const';
import { useUserStore } from '@/stores/user.store';
import type { ErrorResponse } from '@/types/common/common.type';
import { getConfigs, redirectTo, showCommonErrorDialog } from '@/utils/common.util';

const userStore = useUserStore();
const { selectedGroupId, joinedGroups, isOwner } = storeToRefs(userStore);
const { t, locale } = useI18n();

const onClickGroupHistoryMenu = () => {
  const { STUDIO_V2_URL } = getConfigs();
  const url = `${STUDIO_V2_URL}/${locale.value}/${selectedGroupId.value}/business-bank/management-history`;
  redirectTo(url, { external: true });
};

const onClickDiscontinueMenu = async () => {
  const result = await showConfirm({
    content: [
      t('studio.business_bank_setting.group_discontinue_guide_popup_msg1'),
      '<br/>',
      t('studio.business_bank_setting.group_discontinue_guide_popup_msg2')
    ],
    confirmLabel: t('studio.common.popup_case_cf_btn'),
    cancelLabel: t('studio.common.popup_case_cancel_btn'),
    cancelVariant: 'outline'
  });

  if (result) {
    try {
      const res = await deleteGroupApi(selectedGroupId.value);
      if (res) {
        await showAlert({
          content: t('studio.business_bank_setting.group_discontinue_completed_popup_msg'),
          confirmLabel: t('studio.common.popup_case_cf_btn')
        });

        if (joinedGroups.value && joinedGroups.value.length > 1) {
          if (joinedGroups.value[0] && joinedGroups.value[0].groupId !== selectedGroupId.value) {
            redirectTo(`/${locale.value}${HOME_PAGE_URL}`, {
              groupId: joinedGroups.value[0].groupId,
              external: true
            });
          } else {
            redirectTo(`/${locale.value}${HOME_PAGE_URL}`, {
              groupId: joinedGroups.value[1].groupId,
              external: true
            });
          }

          return;
        }
        redirectTo(GROUP_REGISTER_PAGE_URL);
      }
    } catch (error) {
      const { statusCode } = error as ErrorResponse;
      if (
        [
          STATUS_CODE.GROUP_DELETE_DISABLE,
          STATUS_CODE.GROUP_MIGRATION_ALREADY_PROGRESS,
          STATUS_CODE.PROJECT_MIGRATION_ALREADY_PROGRESS
        ].includes(statusCode)
      ) {
        showAlert({
          content: [
            t('studio.business_bank_setting.group_discontinue_not_allowed_popup_msg1'),
            '<br/>',
            t('studio.business_bank_setting.group_discontinue_not_allowed_popup_msg2')
          ],
          confirmLabel: t('studio.common.popup_case_cf_btn')
        });
      } else {
        showCommonErrorDialog(COMMON_ERROR_MESSAGE_KEY);
      }
    }
  }
};
</script>
