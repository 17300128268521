<template>
  <div class="flex-1 flex flex-col">
    <div
      v-if="
        status === BUSINESS_BANK_STATUS.REQUEST ||
          (status === BUSINESS_BANK_STATUS.REJECT && bizCheckedYn === Confirmation.NO)
      "
      class="flex flex-col mt-24 items-center"
    >
      <img
        :src="
          status === BUSINESS_BANK_STATUS.REQUEST
            ? ImageAssets.BusinessBankImage3
            : ImageAssets.BusinessBankImage4
        "
        alt=""
        class="block"
      />
      <safe-html
        v-if="title"
        as="p"
        class="mt-12 text-4xl leading-3xl font-bold text-on-surface-elevation-1 text-center"
        :html="title"
      />
      <safe-html
        v-if="subTitle"
        as="p"
        class="mt-8 text-md leading-lg font-regular text-on-surface-elevation-3"
        :class="status === BUSINESS_BANK_STATUS.REJECT ? 'text-left mb-12 whitespace-pre-line wrap-anywhere' : 'text-center'"
        :html="subTitle"
      />
    </div>
    <div v-else class="mt-28 grid grid-cols-2 gap-x-16 gap-y-28">
      <template v-for="(businessBankItem, idx) of infoItems" :key="idx">
        <dl
          v-if="businessBankItem.show"
          :class="businessBankItem.fullRow ? 'col-span-2' : 'col-span-1'"
          class="flex flex-col gap-4"
        >
          <dt class="text-md leading-sm font-medium text-on-surface-elevation-4">
            {{ $t(businessBankItem.title) }}
          </dt>
          <dd class="text-xl leading-xl font-bold text-on-surface-elevation-2">
            <s-tooltip
              arrow
              :content="`<p class='break-all'>${businessBankItem.value}</p>`"
              :duration="0"
              :distance="4"
              useFlip
              flipOnUpdate
              placement="bottom-start"
              trigger="mouseenter focus"
              :theme="'studio-tooltip'"
              :zIndex="600"
              :allowHTML="true"
            >
              <template #target>
                <span class="line-clamp-2">{{ businessBankItem.value }}</span>
              </template>
            </s-tooltip>
          </dd>
        </dl>
      </template>
    </div>
    <div class="mt-auto flex gap-12 justify-center shrink-0">
      <s-button
        variant="tertiary"
        size="md"
        class="min-w-[11.2rem] hover-interaction-overlay"
        @click="onClick(BUSINESS_ACTION_BTN.VIEW)"
      >
        <!-- 심사 내역 -->
        {{ $t('studio.business_bank_setting.btn_details') }}
      </s-button>
      <s-button
        variant="secondary"
        size="md"
        class="min-w-[11.2rem] hover-interaction-overlay-2"
        @click="onClick(BUSINESS_ACTION_BTN.EDIT)"
      >
        <!-- 제출 내용 -->
        {{
          status === BUSINESS_BANK_STATUS.REQUEST
            ? $t('studio.business_bank_setting.btn_info')
            : $t('studio.business_bank_setting.btn_modify')
        }}
      </s-button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import * as ImageAssets from '@/assets/images/business-bank';
import SafeHtml from '@/components/common/safe-html.vue';
import { BUSINESS_ACTION_BTN, BUSINESS_BANK_STATUS } from '@/constants/business-bank.const';
import { Confirmation } from '@/enums/common.enum';

interface BusinessBankItem {
  title: string;
  value: string | number;
  show?: boolean;
  fullRow?: boolean;
}

defineProps<{
  title?: string;
  subTitle?: string;
  status: string;
  isBank?: boolean;
  bizCheckedYn?: string;
  infoItems: BusinessBankItem[];
  isBusinessInfo?: boolean;
}>();

const emit = defineEmits<{
  onAction: [v: string];
}>();

const onClick = (act: string) => {
  emit('onAction', act);
};
</script>
