<template>
  <st-box
    businessBank
    :useLink="bankEmpty"
    :targetLink="targetLink"
    class="col-span-1"
    :class="{
      'group col-span-1 hover:shadow-[0_.8rem_2rem_0_rgba(0,51,150,.16)] transition-all duration-300 ease-in-out':
        bankEmpty && !isRejectionOnly,
      'relative group col-span-1 transition-all duration-300 ease-in-out': isRejectionOnly
    }"
  >
    <header-area
      :title="$t('studio.business_bank_setting.title2')"
      :linkUse="bankEmpty"
      :buttonUse="buttonUse"
      @rejectAction="rejectAction"
      @redirectTo="register"
    />
    <business-bank-empty
      v-if="bankEmpty"
      :title="$t('studio.business_bank_setting.guide2')"
      isBank
      :isRejectionOnly="isRejectionOnly"
      @onViewHistory="goToReviewHistory"
    >
      <safe-html
        as="p"
        class="text-md leading-lg font-regular text-on-surface-elevation-3"
        :html="$t('studio.business_bank_setting.bank_component_guide2_1')"
      />
    </business-bank-empty>
    <business-bank-status-info
      v-else
      :infoItems="infoItems"
      :title="$t(statusInfo.title)"
      :subTitle="$t(statusInfo.subTitle)"
      :status="bankStatus"
      :bizCheckedYn="bankCheckedYn"
      @onAction="showRegister"
    />
  </st-box>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { fetchBankHistoriesApi, previewCheckBankInfoApi } from '@/apis/business-bank-info.api';
import BusinessBankEmpty from '@/components/business-bank-info/common/business-bank-empty.vue';
import BusinessBankStatusInfo from '@/components/business-bank-info/common/business-bank-status-info.vue';
import HeaderArea from '@/components/business-bank-info/common/header-area.vue';
import safeHtml from '@/components/common/safe-html.vue';
import StBox from '@/components/common/st-box.vue';
import { BUSINESS_ACTION_BTN, BUSINESS_BANK_STATUS } from '@/constants/business-bank.const';
import { DEFAULT_COUNTRY } from '@/constants/cookie.const';
import { BANK_LIST_PAGE_URL, BANK_REGISTER_PAGE_URL } from '@/constants/url.const';
import { Confirmation } from '@/enums/common.enum';
import { useBusinessBankStore } from '@/stores/business-bank.store';
import { useUserStore } from '@/stores/user.store';
import type { BankListContentModel } from '@/types/bank-info/bank-model.type';
import { redirectTo } from '@/utils/common.util';

const { locale, t } = useI18n();

const userStore = useUserStore();
const { selectedGroupId } = storeToRefs(userStore);

const businessBankStore = useBusinessBankStore();
const { bankStatus, bankApproval, bankCheckedYn } = storeToRefs(businessBankStore);
const { fetchBankInfos } = businessBankStore;
const hasApprovedBankInfo = ref<boolean>(false);

const showRegister = (act: string) => {
  switch (act) {
    case BUSINESS_ACTION_BTN.VIEW:
      redirectTo(BANK_LIST_PAGE_URL);
      break;
    case BUSINESS_ACTION_BTN.EDIT:
      redirectTo(BANK_REGISTER_PAGE_URL);
      break;
    case BUSINESS_ACTION_BTN.DELETE:
      break;
    default:
      break;
  }
};

const register = () => {
  redirectTo(BANK_REGISTER_PAGE_URL);
};

const rejectAction = async () => {
  if (bankCheckedYn.value === Confirmation.YES) {
    return;
  }
  const res = await previewCheckBankInfoApi(selectedGroupId.value);
  if (res && res.checked) {
    await fetchBankInfos();
  }
};

const targetLink = computed(() => {
  return `/${locale.value}/${selectedGroupId.value}/business-bank/bank-register`;
});

const buttonUse = computed(
  () => bankStatus.value === BUSINESS_BANK_STATUS.REJECT && bankCheckedYn.value === Confirmation.NO
);

const isRejectionOnly = computed(
  () =>
    bankStatus.value === BUSINESS_BANK_STATUS.REJECT &&
    bankCheckedYn.value === Confirmation.YES &&
    !hasApprovedBankInfo.value
);
const bankEmpty = computed(() => bankStatus.value === '' || isRejectionOnly.value);

const statusInfo = computed(() => {
  let title = '';
  let subTitle = '';

  switch (bankStatus.value) {
    case BUSINESS_BANK_STATUS.REQUEST:
      title = 'studio.business_bank_setting.in_review_guide2';
      subTitle = 'studio.business_bank_setting.in_review_guide2_1';
      break;
    case BUSINESS_BANK_STATUS.REJECT:
      title = 'studio.business_bank_setting.reject1';
      subTitle = bankApproval.value?.rejectReason || '';
      break;

    default:
      title = '';
      subTitle = '';
      break;
  }

  return {
    title,
    subTitle
  };
});

const infoItems = computed(() => {
  if (!bankApproval.value) {
    return [];
  }
  return [
    {
      title: 'studio.business_bank_setting.bank_info1',
      value: t(`studio.national_code.${bankApproval.value.nation.toLowerCase()}`),
      fullRow: true,
      show: true
    },
    {
      title: 'studio.business_bank_setting.bank_info2',
      value: bankApproval.value.bankName,
      show: true
    },
    {
      title: 'studio.business_bank_setting.bank_info3',
      value: bankApproval.value.accountHolder,
      show: true
    },
    {
      title: 'studio.business_bank_setting.bank_info4',
      value: bankApproval.value.accountNumber,
      show: true
    },
    {
      title: 'studio.business_bank_setting.bank_info5',
      value: bankApproval.value.swiftCode,
      show: bankApproval.value.nation !== DEFAULT_COUNTRY
    }
  ];
});

const goToReviewHistory = () => {
  redirectTo(BANK_LIST_PAGE_URL);
};

const init = async () => {
  if (bankStatus.value !== BUSINESS_BANK_STATUS.APPROVE) {
    await fetchBankInfos();
  }
  const result = await fetchBankHistoriesApi(selectedGroupId.value);
  if (result && result.contents.length > 0) {
    hasApprovedBankInfo.value = result.contents.some(
      (item: BankListContentModel) => item.bankAccountStatus === BUSINESS_BANK_STATUS.APPROVE
    );
  }
};
init();
</script>
