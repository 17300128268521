<template>
  <st-box
    businessBank
    :useLink="businessEmpty"
    :targetLink="targetLink"
    class="col-span-1"
    :class="{
      'group hover:shadow-[0_.8rem_2rem_0_rgba(0,51,150,.16)] transition-all duration-300 ease-in-out':
        businessEmpty && !isRejectionOnly,
      'relative group col-span-1 transition-all duration-300 ease-in-out': isRejectionOnly
    }"
  >
    <header-area
      :title="$t('studio.business_bank_setting.title1')"
      :linkUse="businessEmpty"
      :buttonUse="buttonUse"
      @rejectAction="rejectAction"
      @redirectTo="register"
    />
    <business-bank-empty
      v-if="businessEmpty"
      :title="$t('studio.business_bank_setting.guide1')"
      :subTitle="$t('studio.business_bank_setting.guide1_1')"
      :isRejectionOnly="isRejectionOnly"
      @onViewHistory="goToReviewHistory"
    >
      <p class="flex items-start gap-4 text-md leading-lg font-regular text-on-surface-elevation-3">
        <s-icon
          icon="ic-v2-community-person-line"
          size="xl"
          class="text-on-surface-elevation-2 flex mt-4 shrink-0"
        />
        {{ $t('studio.business_bank_setting.guide1_2') }}
      </p>
      <p class="flex items-start gap-4 text-md leading-lg font-regular text-on-surface-elevation-3">
        <s-icon
          icon="ic-v2-community-group-line"
          size="xl"
          class="text-on-surface-elevation-2 flex mt-4 shrink-0"
        />
        {{ $t('studio.business_bank_setting.guide1_4') }}
      </p>
    </business-bank-empty>
    <business-bank-status-info
      v-else
      :infoItems="infoItems"
      :title="$t(statusInfo.title)"
      :subTitle="$t(statusInfo.subTitle)"
      :status="businessStatus"
      :bizCheckedYn="bizCheckedYn"
      @onAction="showRegister"
    />
  </st-box>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import {
  fetchBusinessHistoriesApi,
  previewCheckBusinessInfoApi
} from '@/apis/business-bank-info.api';
import BusinessBankEmpty from '@/components/business-bank-info/common/business-bank-empty.vue';
import businessBankStatusInfo from '@/components/business-bank-info/common/business-bank-status-info.vue';
import HeaderArea from '@/components/business-bank-info/common/header-area.vue';
import StBox from '@/components/common/st-box.vue';
import { useApp } from '@/composables/useApp';
import {
  BUSINESS_ACTION_BTN,
  BUSINESS_BANK_STATUS,
  BUSINESS_TYPE
} from '@/constants/business-bank.const';
import { DEFAULT_COUNTRY } from '@/constants/cookie.const';
import { BUSINESS_LIST_PAGE_URL, BUSINESS_REGISTER_PAGE_URL } from '@/constants/url.const';
import { Confirmation } from '@/enums/common.enum';
import { useBusinessBankStore } from '@/stores/business-bank.store';
import { useUserStore } from '@/stores/user.store';
import type { BusinessListContentModel } from '@/types/business-info/business-model.type';
import { redirectTo } from '@/utils/common.util';

const { locale, t } = useI18n();
const { checkMenuWritable } = useApp();

const userStore = useUserStore();
const { selectedGroupId } = storeToRefs(userStore);

const businessBankStore = useBusinessBankStore();
const {
  businessApproval,
  businessStatus,
  companyLicense,
  bizCheckedYn,
  businessInfo,
  businessInfos
} = storeToRefs(businessBankStore);
const { fetchBusinessInfos } = businessBankStore;
const hasApprovedBusinessBankInfo = ref<boolean>(false);

const showRegister = (act: string) => {
  switch (act) {
    case BUSINESS_ACTION_BTN.VIEW:
      redirectTo(BUSINESS_LIST_PAGE_URL);
      break;
    case BUSINESS_ACTION_BTN.EDIT:
      redirectTo(BUSINESS_REGISTER_PAGE_URL);
      break;
    case BUSINESS_ACTION_BTN.DELETE:
      break;
    default:
      break;
  }
};

const register = () => {
  redirectTo(BUSINESS_REGISTER_PAGE_URL);
};

const rejectAction = async () => {
  if (!(await checkMenuWritable())) {
    return;
  }

  if (bizCheckedYn.value === Confirmation.YES) {
    return;
  }
  const res = await previewCheckBusinessInfoApi(selectedGroupId.value);
  if (res && res.checked) {
    await fetchBusinessInfos();
  }
};

const targetLink = computed(() => {
  return `/${locale.value}/${selectedGroupId.value}/business-bank/business-register`;
});

const buttonUse = computed(
  () =>
    businessStatus.value === BUSINESS_BANK_STATUS.REJECT && bizCheckedYn.value === Confirmation.NO
);

const isRejectionOnly = computed(
  () =>
    businessStatus.value === BUSINESS_BANK_STATUS.REJECT &&
    bizCheckedYn.value === Confirmation.YES &&
    !hasApprovedBusinessBankInfo.value
);
const businessEmpty = computed(() => businessStatus.value === '' || isRejectionOnly.value);

const statusInfo = computed(() => {
  let title = '';
  let subTitle = '';

  switch (businessStatus.value) {
    case BUSINESS_BANK_STATUS.REQUEST:
      title = 'studio.business_bank_setting.in_review_guide1';
      subTitle = 'studio.business_bank_setting.in_review_guide1_1';
      break;
    case BUSINESS_BANK_STATUS.REJECT:
      title = 'studio.business_bank_setting.reject1';
      subTitle = businessApproval.value?.rejectReason || '';
      break;

    default:
      title = '';
      subTitle = '';
      break;
  }

  return {
    title,
    subTitle
  };
});

const infoItems = computed(() => {
  if (!businessApproval.value) {
    return [];
  }
  const { nation } = businessApproval.value;
  const isCountryNameFullRow =
    companyLicense.value === Confirmation.NO || companyLicense.value === Confirmation.YES;
  const isCompanyNameFullRow = nation !== DEFAULT_COUNTRY;
  const isCeoNameFullRow = companyLicense.value === Confirmation.NO;
  return [
    {
      title: 'studio.business_bank_setting.business_info1',
      value:
        businessInfos.value?.individual.license === Confirmation.YES
          ? t(`studio.national_code.${businessInfo.value?.nation?.toLowerCase()}`)
          : t(`studio.national_code.${businessApproval.value.nation?.toLowerCase()}`),
      fullRow: isCountryNameFullRow,
      show: true
    },
    {
      title: 'studio.business_bank_setting.business_info4',
      value: businessApproval.value.companyRegisNum || '',
      show: companyLicense.value === Confirmation.YES
    },
    {
      title: 'studio.business_bank_setting.business_info5',
      value:
        businessApproval.value.businessType === BUSINESS_TYPE.PERSONAL
          ? t('studio.business_bank_setting.list_biz_type_indiv')
          : businessApproval.value.businessType === BUSINESS_TYPE.INDIVIDUAL_BUSINESS
            ? t('studio.business_bank_setting.list_biz_type_indiv_biz_owner')
            : t('studio.business_bank_setting.list_biz_type_business'),
      show: companyLicense.value === Confirmation.YES
    },
    {
      title: 'studio.business_bank_setting.business_info6',
      value: businessApproval.value.companyName,
      fullRow: isCountryNameFullRow ? false : isCompanyNameFullRow,
      show: companyLicense.value === Confirmation.YES
    },
    {
      title:
        companyLicense.value === Confirmation.NO
          ? 'studio.business_bank_setting.business_info2'
          : 'studio.business_bank_setting.business_info7',
      value: businessApproval.value.ceoName,
      fullRow:
        companyLicense.value === Confirmation.NO
          ? true
          : isCountryNameFullRow || isCompanyNameFullRow
            ? false
            : isCeoNameFullRow,
      show: companyLicense.value === Confirmation.NO || companyLicense.value === Confirmation.YES
    },
    {
      title: 'studio.business_bank_setting.business_info3',
      value: businessApproval.value.ceoBirth,
      // show: false
      show: companyLicense.value === Confirmation.NO
    }
  ];
});

const goToReviewHistory = () => {
  redirectTo(BUSINESS_LIST_PAGE_URL);
};

const init = async () => {
  if (businessStatus.value !== BUSINESS_BANK_STATUS.APPROVE) {
    await fetchBusinessInfos();
  }
  const result = await fetchBusinessHistoriesApi(selectedGroupId.value);
  if (result && result.contents.length > 0) {
    hasApprovedBusinessBankInfo.value = result.contents.some(
      (item: BusinessListContentModel) => item.companyStatus === BUSINESS_BANK_STATUS.APPROVE
    );
  }
};

init();
</script>
